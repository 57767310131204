import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { PanAppState, PlatformEnum } from '@panamax/app-state';
import { CustomerSummary } from '@usf/customer-types/customer';
import { CustomerSelectionOption } from '@app/customer/models/customer-selection-option.model';
import { CustomerSelectionVM } from '@app/customer/models/customer-selection-vm.model';
import { CustomerService } from '@app/customer/services/customer.service';
import { DepartmentSummary } from '@usf/customer-types/department';
import { Observable, map } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';

@Component({
  selector: 'app-super-user-customers-modal',
  templateUrl: './super-user-customers-modal.component.html',
  styleUrls: ['./super-user-customers-modal.component.scss'],
})
export class SuperUserCustomersModalComponent implements ViewWillEnter, OnInit {
  readonly platformEnum = PlatformEnum;

  @Input() platform: PlatformEnum;
  @Input() favoriteFlag: boolean;

  @Output()
  isSuperUserCustomersModalOpenEmitter = new EventEmitter<boolean>();
  @Output()
  superUserCustomerSelectedEmitter =
    new EventEmitter<CustomerSelectionOption>();
  @Output()
  superUserCustomerFavoriteEmitter =
    new EventEmitter<CustomerSelectionOption>();

  superUserCustomersModalVM$: Observable<CustomerSelectionVM>;
  selectedCustomer: CustomerSelectionOption;
  searchKey: string;
  currentPage = 1;

  superUserFavoriteCustomerFeatureFlag$: Observable<boolean>;

  constructor(
    protected customerService: CustomerService,
    readonly panAppState: PanAppState,
  ) {}

  ngOnInit() {
    this.superUserFavoriteCustomerFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_super_user_favorite_customer,
    ]);
  }

  ionViewWillEnter() {
    this.resetModalData();
  }

  resetModalData() {
    this.searchKey = '';
    this.selectedCustomer = undefined;
    this.resetSearchResults();
  }

  resetSearchResults() {
    this.customerService.setFilteredCustomerSummaries([]);
    this.changePage(1);
  }

  search(event: any) {
    this.resetSearchResults();
    let searchKey = event?.target?.value?.trim();
    if (searchKey && searchKey.length >= 3) {
      this.searchKey = searchKey;
      this.getSuperUserCustomerSelectionVM$();
    }
  }

  getSuperUserCustomerSelectionVM$() {
    this.customerService.setShowCustomersDataLoadingSpinner(true);
    this.customerService.searchCustomers(this.searchKey);
    this.superUserCustomersModalVM$ =
      this.customerService.filteredCustomerSummaries$.pipe(
        map(customerSummaries => {
          return this.buildSuperUserCustomerSelectionVM(customerSummaries);
        }),
      );
  }

  buildSuperUserCustomerSelectionVM(customerSummaries: CustomerSummary[]) {
    const customerSelectionOptions: CustomerSelectionOption[] = [];

    customerSummaries.forEach(customer => {
      this.buildCustomersList(customerSelectionOptions, customer);
      this.buildDepartmentsList(
        customerSelectionOptions,
        customer.customerNumber,
        customer.departments,
      );
    });
    const vm: CustomerSelectionVM = {
      customerSelectionOptions,
    };
    return vm;
  }

  buildCustomersList(
    customerSelectionOptions: CustomerSelectionOption[],
    customer: CustomerSummary,
  ) {
    const customerName = customer.customerName;
    const state =
      customer.state !== null && customer.state !== undefined
        ? customer.state.toUpperCase()
        : '';
    const city =
      customer.city !== null && customer.city !== undefined
        ? customer.city
            .split(' ')
            .filter(word => word.length > 0)
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ')
        : '';
    const customerInfo: CustomerSelectionOption = {
      title:
        customerName + ' (' + customer.customerNumber + ')' ??
        'No Customer Name',
      subtitle: state.length
        ? (city.length ? city + ', ' : '') + state
        : city.length
          ? city
          : '',
      firstLetter: customerName.charAt(0) ?? '',
      customerName,
      customerNumber: customer.customerNumber,
      divisionNumber: customer.divisionNumber,
      hasDepartments: customer.departments.length ? true : false,
      departmentNumber: '0',
      isDepartment: false,
    };
    customerSelectionOptions.push(customerInfo);
  }

  buildDepartmentsList(
    customerSelectionOptions: CustomerSelectionOption[],
    customerNumber: number,
    departments: DepartmentSummary[],
  ) {
    departments?.forEach(department => {
      const departmentInfo: CustomerSelectionOption = {
        title:
          department.departmentName + ' (' + department.departmentNumber + ')',
        customerNumber,
        divisionNumber: department.divisionNumber,
        departmentNumber: department.departmentNumber,
        isDepartment: true,
      };
      customerSelectionOptions.push(departmentInfo);
    });
  }

  changePage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  handleDismiss() {
    this.isSuperUserCustomersModalOpenEmitter.emit(false);
  }

  handleSuperUserCustomerSelected() {
    this.superUserCustomerSelectedEmitter.emit(this.selectedCustomer);
  }

  toggleIsFavorite(event: any, customer: CustomerSelectionOption) {
    event.stopPropagation(); // Prevents the click event from bubbling up to the parent
    this.superUserCustomerFavoriteEmitter.emit(customer);
  }
}
