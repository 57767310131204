import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  PanAppState,
  PlatformEnum,
  PlatformService,
  Tracking,
} from '@panamax/app-state';
import {
  GetAccessibleListResponse,
  List,
  ListGroup,
  ListGroupKey,
  ListItem,
  ListItemKey,
  ListKey,
  TempItemKey,
  replacementProducts,
} from '@usf/list-types';
import {
  ACCESSIBLE_LIST_ACTIONS,
  DownloadListOptions,
  LIST_ACTIONS,
  LIST_GROUP_ACTIONS,
  LIST_ITEM_ACTIONS,
  ListConstants,
  ListGroupState,
  ListItemState,
  ListState,
  ListTracingConstants,
  RECENT_PURCHASE_ACTIONS,
  addProductsToListsTransformer,
  copyItemsTransformer,
  createGroupTransformer,
  deleteItemsTransformer,
  deleteListGroupTransformer,
  deleteListTransformer,
  displayListDetailsTransformer,
  editGroupNameTransformer,
  editGroupSequenceTransformer,
  editListNameTransformer,
  editListTypeTransformer,
  moveItemsToAnotherGroupTransformer,
  moveItemsWithinGroupTransformer,
  replaceItemOnAllListsTransformer,
  replaceItemOnListTransformer,
  searchInListTransformer,
  selectAllAccessibleListState,
  selectIsAccessibleListStateLoaded,
  selectListImportErrors,
  selectListsState,
} from '@usf/ngrx-list';
import {
  LoadingState,
  getLoadingStateOfProducts,
  productDetailLoaded,
  selectProductDetails,
} from '@usf/ngrx-product';
import {
  AddToGroupPosition,
  Preferences,
  SortFilter,
} from '@usf/user-types/user-preference';
import {
  EMPTY,
  Observable,
  combineLatest,
  firstValueFrom,
  iif,
  of,
} from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { UsfProductCardModeEnum } from 'src/app/shared/constants/usf-product-card-mode.enum';
import { State } from 'src/app/store';
import { MessageTypeEnum } from '../../../../ngrx-message/constants/messageTypeEnum';
import {
  IMPORT_ERROR_TOAST_HEIGHT,
  IMPORT_ERROR_TOAST_MOBILE_HEIGHT,
  ListTypes,
  UsfListDetailManagementHeights,
} from '../../../../shared/constants/lists-constants';
import { FEATURES } from '../../../../shared/constants/splitio-features';
import { Product } from '../../../../shared/models/product.model';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner/loading-spinner.service';
import { ToastService } from '../../../../shared/services/toast/toast.service';
import { UserService } from '../../../../user/services';
import { UserActions } from '../../../../user/store/actions/action-types';
import { mslProductLoadCheck } from '../../../pages/master-list/selectors/master-list.selectors';
import { ListAnalyticsHelperService } from '../../../services/list-analytics-helper.service';
import {
  ListDetailViewModel,
  ListManagementViewModel,
} from '../model/list-detail-management-view.model';
import { SelectedGroup } from '../model/selected-group.model';
import { SelectedItem } from '../model/selected-product.model';
import { createListItemKey } from '../selectors/helpers/list-detail-management.selectors.helper';
import {
  filterProductNumbersByGroup,
  productNumbersOnList,
  selectGroupsByListKey,
  selectListGroup,
  selectListHeader,
  selectListItemsInGroup,
  selectListStatesAreLoaded,
  selectListDetailVm,
  selectListManagementVm,
  isListAndProductAndPricingAndAlternativePricingDataLoaded,
} from '../selectors/list-detail-management-view-model.selector';
import { UserPreferencesState } from '../../../../user/models/user-preferences-state';
import { isPricingLoaded } from '../../../../shared/selectors/product.selectors';
import { getCustomers, getDivisionEntities } from '@app/ngrx-customer/store';
import { MultiSelectItems } from '@shared/constants/dual-pane.enum';
import { Customer, Division } from '@usf/customer-types';
import { CopyProductsModalContainerComponent } from '@app/lists/components/copy-products-modal-container/copy-products-modal-container.component';

@Injectable({
  providedIn: 'root',
})
export class ListDetailManagementService {
  CopyModalRef: CopyProductsModalContainerComponent;
  lastCopyCounts: string;
  constructor(
    protected store: Store<State>,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private loadingSpinnerService: LoadingSpinnerService,
    private toastService: ToastService,
    readonly panAppState: PanAppState,
    private listAnalyticsHelperService: ListAnalyticsHelperService,
    private platformService: PlatformService,
  ) {}

  selectListDetailVm$ = (
    listTypeId: string,
    listId: string,
  ): Observable<ListDetailViewModel> => {
    const listDetailVm$ = this.store.select(
      selectListDetailVm(
        listTypeId,
        Number(listId),
        this.platformService.platformType,
      ),
    );

    const orderMinimumMetFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_min_order_met,
    ]);
    const listItemNoteFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_list_item_note,
    ]);

    const downloadPrintFlag$ = this.panAppState.feature$([
      FEATURES.split_global_download_print,
    ]);

    return combineLatest([
      listDetailVm$,
      orderMinimumMetFeatureFlag$,
      listItemNoteFeatureFlag$,
      this.selectListImportErrors$(listTypeId, listId),
      downloadPrintFlag$,
      this.selectListProductDataLoadingState$(listTypeId, listId),
    ]).pipe(
      map(
        ([
          listVm,
          orderMinimumMetFeatureFlag,
          listItemNoteFeatureFlag,
          importErrors,
          downloadPrintFlag,
          productLoadingState,
        ]) => {
          const vm = {
            ...listVm,
            orderMinimumMetFeatureFlag,
            listItemNoteFeatureFlag,
            importErrors,
            downloadPrintFlag,
            productLoadingState,
          };
          if (
            vm.importErrors !== undefined &&
            vm.importErrors.errors.length > 0
          ) {
            vm.itemHeights[0] =
              vm.itemHeights[0] +
              (this.platformService.platformType === PlatformEnum.mobile
                ? IMPORT_ERROR_TOAST_MOBILE_HEIGHT
                : IMPORT_ERROR_TOAST_HEIGHT);
          }
          return vm;
        },
      ),
    );
  };

  selectListManagementViewModel$ = (
    listTypeId: string,
    listId: string,
  ): Observable<ListManagementViewModel> => {
    const listItemNoteFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_list_item_note,
    ]);

    const downloadPrintFlag$ = this.panAppState.feature$([
      FEATURES.split_global_download_print,
    ]);
    const multipleProductFlag = this.panAppState.feature$([
      FEATURES.split_global_add_multiple_products,
    ]);
    const listManagementVm$ = this.store.select(
      selectListManagementVm(
        listTypeId,
        Number(listId),
        this.platformService.platformType,
      ),
    );

    return combineLatest([
      listItemNoteFeatureFlag$,
      downloadPrintFlag$,
      listManagementVm$,
      multipleProductFlag,
    ]).pipe(
      map(
        ([
          listItemNoteFeatureFlag,
          downloadPrintFlag,
          listManagementVm,
          multipleProductFlag,
        ]) => {
          return {
            ...listManagementVm,
            listItemNoteFeatureFlag,
            downloadPrintFlag,
            multipleProductFlag,
          } as ListManagementViewModel;
        },
      ),
    );
  };

  selectListImportErrors$ = (listTypeId: string, listId: string) => {
    return this.store.select(
      selectListImportErrors({
        listTypeId,
        listId: Number(listId).valueOf(),
      }),
    );
  };

  isListAndProductAndPricingDataLoaded$ = (
    listTypeId: string,
    listId: string,
    needDetails = false,
  ) => {
    return combineLatest([
      this.productNumbers$(listTypeId, listId),
      this.store.select(selectListStatesAreLoaded()),
      this.store.select(mslProductLoadCheck),
    ]).pipe(
      filter(
        ([productNumbers, listsAreLoaded, mslProductsAreLoaded]) =>
          listsAreLoaded && mslProductsAreLoaded,
      ),
      switchMap(([productNumbers, listsAreLoaded]) =>
        combineLatest([
          this.store.select(
            getLoadingStateOfProducts(
              productNumbers.filter(number => !!number),
            ),
          ),
          this.store.select(
            isPricingLoaded(productNumbers.filter(number => !!number)),
          ),
          iif(
            () => needDetails,
            this.store.select(
              selectProductDetails(productNumbers.filter(number => !!number)),
            ),
            of([{ loadingState: LoadingState.loaded }]),
          ),
        ]).pipe(
          map(([productLoadingState, pricesAreLoaded, details]) => {
            const isLoaded = this.isProductDetailsLoaded(details);
            return (
              (productLoadingState === LoadingState.loaded ||
                productLoadingState === LoadingState.error) &&
              pricesAreLoaded &&
              isLoaded
            );
          }),
        ),
      ),
    );
  };

  isProductDetailsLoaded = (productDetail: any[]) => {
    return productDetail.every(detail => {
      return (
        detail?.loadingState === LoadingState.loaded ||
        detail?.loadingState === LoadingState.notFound ||
        detail?.loadingState === LoadingState.error
      );
    });
  };

  isListAndProductAndPricingAndAlternativePricingDataLoaded$ = (
    listTypeId: string,
    listId: string,
  ) =>
    this.store.select(
      isListAndProductAndPricingAndAlternativePricingDataLoaded(
        listTypeId,
        listId,
      ),
    );

  selectListProductDataLoadingState$ = (
    listTypeId: string,
    listId: string,
    needDetails = false,
  ): Observable<LoadingState> => {
    return combineLatest([
      this.productNumbers$(listTypeId, listId),
      this.areListStatesLoaded$(),
      this.store.select(mslProductLoadCheck),
    ]).pipe(
      filter(
        ([productNumbers, listsAreLoaded, mslProductsAreLoaded]) =>
          listsAreLoaded && mslProductsAreLoaded,
      ),
      switchMap(([productNumbers, listsAreLoaded]) =>
        combineLatest([
          this.store.select(
            getLoadingStateOfProducts(
              productNumbers.filter(number => !!number),
            ),
          ),
          iif(
            () => needDetails,
            this.store.select(
              selectProductDetails(productNumbers.filter(number => !!number)),
            ),
            of([{ loadingState: LoadingState.loaded }]),
          ),
        ]).pipe(
          map(([productLoadingState, details]) => {
            const isLoaded = this.isProductDetailsLoaded(details);
            if (!!isLoaded || productLoadingState === LoadingState.error) {
              return productLoadingState;
            } else {
              return LoadingState.loading;
            }
          }),
        ),
      ),
    );
  };

  areListStatesLoaded$ = () => this.store.select(selectListStatesAreLoaded());

  selectListHeader$ = (listTypeId: string, listId: number) =>
    this.store.select(selectListHeader(listTypeId, listId));

  //Returns only the product numbers that are new to the selected group.
  filterProductNumbers$ = (
    listKey: ListKey,
    groupKey: ListGroupKey,
    productNumbers: number[],
  ) =>
    this.store.select(
      filterProductNumbersByGroup(listKey, groupKey, productNumbers),
    );

  searchInList(searchKey: string, listId: string, listType: string) {
    this.store.dispatch(
      LIST_ACTIONS.searchInList({
        searchKey,
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.searchInList,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                event: ListTracingConstants.searchWithinListEvent,
                spanName: ListTracingConstants.searchWithinListSpan,
                searchKey,
                searchType: ListTracingConstants.searchWithin,
                listId,
                listType,
              },
            },
            transformFunc: searchInListTransformer,
          },
        },
      }),
    );
  }

  searchInListEnd(searchKey: string, listId: string, listType: string) {
    this.store.dispatch(
      LIST_ACTIONS.searchInList({
        searchKey,
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.searchInList,
              isEndOfTrace: true,
              isStartOfTrace: false,
              attributes: {
                event: ListTracingConstants.searchWithinListEndEvent,
                spanName: ListTracingConstants.searchWithinListSpan,
                searchKey,
                searchType: ListTracingConstants.searchWithin,
                listId,
                listType,
              },
            },
            transformFunc: searchInListTransformer,
          },
        },
      }),
    );
  }

  sortAndFilterInList(
    sortType: string,
    groupToSort: string,
    filters: string[],
  ) {
    this.store.dispatch(
      LIST_ACTIONS.sortAndFilterList({
        sortType,
        groupToSort,
        filters,
      }),
    );
  }

  displayListDetailsStartTrace(
    listType: string,
    listId: string,
    numberOfProducts: number,
  ) {
    this.store.dispatch(
      LIST_ACTIONS.displayListDetails({
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.displayListDetails,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                event: ListTracingConstants.displayListDetailsEvent,
                spanName: ListTracingConstants.displayListDetailsSpan,
                listType,
                listId,
                numberOfProducts,
              },
            },
            transformFunc: displayListDetailsTransformer,
          },
        },
      }),
    );
  }

  displayListDetailsSuccessTrace(
    listType: string,
    listId: string,
    numberOfProducts: number,
  ) {
    this.store.dispatch(
      LIST_ACTIONS.displayListDetailsSuccess({
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.displayListDetails,
              isEndOfTrace: true,
              isStartOfTrace: false,
              attributes: {
                event:
                  ListTracingConstants.displayListDetailsEvent +
                  ListTracingConstants.eventSuccess,
                spanName:
                  ListTracingConstants.displayListDetailsSpan +
                  ListTracingConstants.spanSuccess,
                listType,
                listId,
                numberOfProducts,
              },
            },
            transformFunc: displayListDetailsTransformer,
          },
        },
      }),
    );
  }

  displayListDetailsFailureTrace(
    listType: string,
    listId: string,
    numberOfProducts: number,
  ) {
    this.store.dispatch(
      LIST_ACTIONS.displayListDetailsFailure({
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.displayListDetails,
              isEndOfTrace: true,
              isStartOfTrace: false,
              attributes: {
                event:
                  ListTracingConstants.displayListDetailsEvent +
                  ListTracingConstants.eventFailure,
                spanName:
                  ListTracingConstants.displayListDetailsSpan +
                  ListTracingConstants.spanFailure,
                listType,
                listId,
                numberOfProducts,
              },
            },
            transformFunc: displayListDetailsTransformer,
          },
        },
      }),
    );
  }

  listGroupsByListKey$ = (listTypeId: string, listId: string) =>
    this.store.select(selectGroupsByListKey(listTypeId, Number(listId)), {
      listTypeId,
      listId: Number(listId),
    });

  productNumbers$ = (listTypeId: string, listId: string) =>
    this.store.select(productNumbersOnList(listTypeId, Number(listId)));

  downloadList = (downloadListOptions: DownloadListOptions) => {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      if (downloadListOptions.listTypeId === ListTypes.recentlyPurchased) {
        this.store.dispatch(
          RECENT_PURCHASE_ACTIONS.downloadList({ downloadListOptions }),
        );
      } else {
        this.store.dispatch(LIST_ACTIONS.downloadList({ downloadListOptions }));
      }
    });
  };

  selectListGroup$ = (
    listTypeId: string,
    listId: number,
    listGroupId: string,
  ) => this.store.select(selectListGroup(listTypeId, listId, listGroupId));

  selectListItemsInGroup$ = (listKey: ListKey, groupKey: ListGroupKey) =>
    this.store.select(selectListItemsInGroup(listKey, groupKey));

  // LISTS
  getListIdFromRoute(route: ActivatedRouteSnapshot): {
    listTypeId: string;
    listId: string;
  } {
    const listKey = route.paramMap.get('listId').split('-');
    if (listKey.length === 3) {
      const negativeId = '-' + listKey.pop();
      listKey[1] = negativeId;
    }
    if (listKey) {
      const listTypeId = listKey[0] || '';
      const listId = listKey[1] || '';
      if (listTypeId && listId) {
        return { listTypeId, listId };
      } else {
        const url = window.location.pathname;
        this.router.navigate([url.split('/')[1], 'lists']);
      }
    }
  }

  /* Dispatches ngrx list action to edit list name **/
  editListName(newListName: string, list: ListState) {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ACTIONS.editListName({
          list,
          newListName,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  event: ListTracingConstants.editListNameEvent,
                  spanName: ListTracingConstants.editListNameSpan,
                },
              },
              transformFunc: editListNameTransformer,
            },
          },
        }),
      );
    });
  }

  /* Dispatches ngrx list action to edit list type **/
  editListType(newListType: string, list: ListState) {
    this.panAppState.user$.pipe(take(1)).subscribe(user => {
      if (
        list?.listState === ListTypes.private &&
        list?.addUserIdSourceSystem !== user?.userId
      ) {
        this.presentCantEditListTypeToast();
        return;
      }
      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.store.dispatch(
          LIST_ACTIONS.editListType({
            list,
            newListType,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    event: ListTracingConstants.editListTypeEvent,
                    spanName: ListTracingConstants.editListTypeSpan,
                  },
                },
                transformFunc: editListTypeTransformer,
              },
            },
          }),
        );
      });
    });
  }

  presentCantEditListTypeToast() {
    this.loadingSpinnerService.dismissSpinnerModal();
    this.toastService.presentToastMsg(
      this.translateService.instant('i18n.lists.unauthorizedEditListType'),
      'red-toast',
      MessageTypeEnum.error,
      [],
    );
  }

  /* Dispatches ngrx list action to delete list **/
  deleteList(list: ListState) {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ACTIONS.delete({
          list,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  event: ListTracingConstants.deleteListEvent,
                  spanName: ListTracingConstants.deleteListSpan,
                },
              },
              transformFunc: deleteListTransformer,
            },
          },
        }),
      );
    });
  }

  updateLastSelectedList(listTypeId: string, listId: string) {
    this.store
      .select(selectListHeader(listTypeId, Number(listId)))
      .pipe(take(1))
      .subscribe(list => {
        this.store.dispatch(
          LIST_ACTIONS.updateLastSelectedList({ lastSelectedList: list }),
        );
      });
  }

  // GROUPS
  /* Checks to see if the group name exists within these groups */
  groupNameExists(newGroupName: string, groups: ListGroupState[]) {
    for (const group of groups) {
      if (
        newGroupName?.toLowerCase() === group?.groupName?.toLowerCase().trim()
      ) {
        return true;
      }
    }
    return false;
  }

  // Dispatches action to create a new group for a given list
  createGroup(list: ListState, groupName: string) {
    const tempId = Math.floor(-100000000 * Math.random());
    const newGroup = {
      listGroupKey: {
        listTypeId: list.listKey.listTypeId,
        listGroupId: tempId.toString(),
      },
      listKey: {
        listTypeId: list.listKey.listTypeId,
        listId: list.listKey.listId,
      },
      r4TempGroupId: tempId,
      groupName,
      visibleInd: true,
      listItemKeys: [],
    };

    const updatedGroupKeys = list?.listGroupKeys?.slice() || [];
    updatedGroupKeys.push({
      listTypeId: list.listKey.listTypeId,
      listGroupId: tempId.toString(),
    } as ListGroupKey);
    const updatedList = { ...list, listGroupKeys: updatedGroupKeys };
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_GROUP_ACTIONS.create({
          list,
          listGroup: newGroup as any,
          version: updatedList.version as string,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  groupName,
                  event: ListTracingConstants.createGroupEvent,
                  spanName: ListTracingConstants.createGroupSpan,
                },
              },
              transformFunc: createGroupTransformer,
            },
          },
        }),
      );
    });
  }

  /* Dispatches ngrx group action to edit group name */
  editGroupName(newGroupName: string, list: ListState, group: ListGroupState) {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_GROUP_ACTIONS.editGroupName({
          list,
          group,
          newGroupName,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  groupId: group.listGroupKey.listGroupId,
                  event: ListTracingConstants.editGroupNameEvent,
                  spanName: ListTracingConstants.editGroupNameSpan,
                },
              },
              transformFunc: editGroupNameTransformer,
            },
          },
        }),
      );
    });
  }

  /* Dispatches ngrx action to delete a group from the list */
  deleteGroup(
    list: ListState,
    groupToDelete: ListGroupState,
    unassignedGroup: ListGroupState,
    listItems: ListItemState[],
    isDeleteAll: boolean,
  ) {
    // Getting new group keys for list
    const updatedGroupKeys: ListGroupKey[] = [];
    list.listGroupKeys?.forEach(key => {
      if (JSON.stringify(key) !== JSON.stringify(groupToDelete.listGroupKey)) {
        updatedGroupKeys.push(key);
      }
    });
    if (isDeleteAll) {
      const itemsToRemove =
        listItems?.filter(
          item =>
            JSON.stringify(item.listGroupKey) ===
            JSON.stringify(groupToDelete.listGroupKey),
        ) || [];

      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.store.dispatch(
          LIST_ACTIONS.deleteGroupAndDelete({
            list,
            updatedGroupKeys,
            groupToDelete,
            listItemsToDelete: itemsToRemove,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    groupId: groupToDelete.listGroupKey.listGroupId,
                    event: ListTracingConstants.deleteListGroupEvent,
                    spanName: ListTracingConstants.deleteListGroupSpan,
                  },
                },
                transformFunc: deleteListGroupTransformer,
              },
            },
          }),
        );
      });
    } else {
      const itemsToRemove: ListItemState[] = [];
      const itemsToInsert: ListItemState[] = [];
      const unassignedGroupMap = new Map<number, ListItemState>();
      // Loop through listItems in list, if its in group to delete append to itemsToRemove
      // if its unassigned group, add it to the map
      for (const item of listItems) {
        if (
          JSON.stringify(item.listGroupKey) ===
          JSON.stringify(groupToDelete.listGroupKey)
        ) {
          itemsToRemove.push(item);
        }
        if (
          JSON.stringify(item.listGroupKey) ===
          JSON.stringify(unassignedGroup.listGroupKey)
        ) {
          unassignedGroupMap.set(item.productNumber, item);
        }
      }

      const unassignedGroupKeys: ListItemKey[] =
        unassignedGroup.listItemKeys?.slice() || [];
      const keysToInsert: ListItemKey[] = [];
      const additionalItemsToRemove: ListItemState[] = [];
      const indexesToRemoveFromUnassignedGroupKeys: number[] = [];
      // Loop through the items we want to remove, if its in unassigned group,
      // remove the item from unassigned and add the item we are moving there,
      // if not in unassinged, add the item we are moving there
      for (const item of itemsToRemove) {
        if (unassignedGroupMap.has(item.productNumber)) {
          indexesToRemoveFromUnassignedGroupKeys.push(
            unassignedGroupMap.get(item.productNumber).groupSequenceNumber - 1,
          );
          keysToInsert.push(item.listItemKey);
          additionalItemsToRemove.push(
            unassignedGroupMap.get(item.productNumber),
          );
          itemsToInsert.push({
            ...item,
            listGroupKey: unassignedGroup.listGroupKey,
          });
        } else {
          itemsToInsert.push({
            ...item,
            listGroupKey: unassignedGroup.listGroupKey,
          });
          keysToInsert.push(item.listItemKey);
        }
      }
      const updatedUnassignedGroupKeys: ListItemKey[] = [];
      for (let i = 0; i < unassignedGroupKeys.length; i++) {
        if (!indexesToRemoveFromUnassignedGroupKeys.includes(i)) {
          updatedUnassignedGroupKeys.push(unassignedGroupKeys[i]);
        }
      }

      const updatedListItemKeys = keysToInsert.concat(
        updatedUnassignedGroupKeys,
      );
      const allItemsToRemove = itemsToRemove.concat(additionalItemsToRemove);

      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.store.dispatch(
          LIST_ACTIONS.deleteGroupAndMove({
            list,
            updatedGroupKeys,
            groupToDelete,
            unassignedGroup,
            updatedListItemKeys,
            listItemsToDelete: allItemsToRemove,
            listItemsToInsert: itemsToInsert,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    groupId: groupToDelete.listGroupKey.listGroupId,
                    event: ListTracingConstants.deleteListGroupAndMoveEvent,
                    spanName: ListTracingConstants.deleteListGroupAndMoveSpan,
                  },
                },
                transformFunc: deleteListGroupTransformer,
              },
            },
          }),
        );
      });
    }
  }

  getSelectedGroup(selectedGroup: string, groups: ListGroupState[]) {
    for (const group of groups) {
      if (group.groupName === selectedGroup) {
        return group;
      }
    }
  }

  /* Dispatches action to change currently selectedGroup */
  changeSelectedGroup(key: string, newSelectedGroup: string) {
    this.store.dispatch(
      LIST_ACTIONS.changeSelectedGroup({ key, newSelectedGroup }),
    );
  }

  /* Dispatches ngrx group action to resequence groups in a list */
  resequenceGroup(
    list: ListState,
    groupToMove: ListGroupState,
    currentIndex: number,
    targetIndex: number,
  ) {
    const listGroupKeys = list.listGroupKeys.slice();
    listGroupKeys.splice(currentIndex, 1);
    listGroupKeys.splice(targetIndex, 0, groupToMove.listGroupKey);
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ACTIONS.editGroupSequence({
          list,
          group: groupToMove,
          groupKeys: listGroupKeys,
          groupSequenceNumber: targetIndex + 1,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  groupId: groupToMove.listGroupKey.listGroupId,
                  event: ListTracingConstants.resequenceGroupsEvent,
                  spanName: ListTracingConstants.resequenceGroupsSpan,
                },
              },
              transformFunc: editGroupSequenceTransformer,
            },
          },
        }),
      );
    });
  }

  /* Calculates top position of sidebar on list details, recently purchased, and list mangement pages */
  calculateSidebarTopPosition(
    platform: PlatformEnum,
    isListManagement: boolean,
    vsScrollTopPosition: number,
  ) {
    let topPosition = this.setTopPosition(platform, isListManagement);
    if (topPosition) {
      if (vsScrollTopPosition >= topPosition) {
        return 20;
      } else {
        return topPosition - vsScrollTopPosition;
      }
    } else {
      return 0;
    }
  }

  setTopPosition(platform: PlatformEnum, isListManagement: boolean) {
    if (platform === PlatformEnum.desktop) {
      return !isListManagement
        ? UsfListDetailManagementHeights.orderInfoSidebarBaseTopPositionDesktop
        : UsfListDetailManagementHeights.groupsSidebarBaseTopPositionDesktop;
    } else if (platform === PlatformEnum.tablet) {
      return !isListManagement
        ? UsfListDetailManagementHeights.orderInfoSidebarBaseTopPositionTablet
        : UsfListDetailManagementHeights.groupsSidebarBaseTopPositionTablet;
    } else {
      return undefined;
    }
  }

  // PRODUCTS
  goToProductDetail(productId: number, quickSearch: boolean) {
    const url = window.location.pathname;
    if (quickSearch) {
      this.router.navigate([url.split('/')[1], 'products', productId], {
        queryParams: { lnksrc: 'quickView' },
      });
    } else {
      this.router.navigate([url.split('/')[1], 'products', productId]);
    }
  }

  /* Dispatches ngrx action to replace a product within a single list */
  replaceProduct(
    listItems: ListItem[],
    ogProductNum: number,
    replacementProductNum: number,
    list: ListState,
  ) {
    const replacementProduct: replacementProducts = {
      originalProduct: ogProductNum,
      replacementProduct: replacementProductNum,
    };

    const filteredListItems = listItems.filter(
      item => item.listKey.listId === list.listKey.listId,
    );
    const updatedListItems = this.assignNewProdNumForReplaceItems(
      filteredListItems,
      replacementProduct.replacementProduct,
    );

    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ITEM_ACTIONS.replaceProductInAList({
          listItems: updatedListItems,
          product: replacementProduct,
          list,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  originalProductNumber: ogProductNum,
                  replacementProductNumber: replacementProductNum,
                  event: ListTracingConstants.replaceItemOnListEvent,
                  spanName: ListTracingConstants.replaceItemOnListSpan,
                },
              },
              transformFunc: replaceItemOnListTransformer,
            },
          },
        }),
      );
    });
  }

  replaceProductAcrossLists(
    listItems: ListItem[],
    listIds: number[],
    ogProductNum: number,
    replacementProductNum: number,
    list: ListState,
  ) {
    const replacementProduct: replacementProducts = {
      originalProduct: ogProductNum,
      replacementProduct: replacementProductNum,
    };
    const updatedListItems = this.assignNewProdNumForReplaceItems(
      listItems,
      replacementProduct.replacementProduct,
    );
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ITEM_ACTIONS.replaceProductAcrossAllLists({
          listItems: updatedListItems,
          product: replacementProduct,
          listIds,
          list,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listIds,
                  originalProductNumber: ogProductNum,
                  replacementProductNumber: replacementProductNum,
                  event: ListTracingConstants.replaceItemOnListsEvent,
                  spanName: ListTracingConstants.replaceItemOnListsSpan,
                },
              },
              transformFunc: replaceItemOnAllListsTransformer,
            },
          },
        }),
      );
    });
  }

  /* Returns the product numbers for the products that are currently selected on list management page */
  getSelectedProductNumbers(products: ListItemState[]) {
    const ret = [];
    products?.forEach(product => {
      if (product?.isSelected) {
        ret.push(product?.productNumber);
      }
    });
    return ret;
  }

  /* Dispatches action to set specific product card isSelected to a value */
  setSelectedProductCards(key: string, value: boolean) {
    this.store.dispatch(
      LIST_ITEM_ACTIONS.setIsSelected({ key, value, listGroupKey: null }),
    );
  }

  /* Dispatches action to set all product cards isSelected to false */
  setAllSelectedProductCards(value: boolean, selectedGroupKey: ListGroupKey) {
    this.store.dispatch(
      LIST_ITEM_ACTIONS.setIsSelected({
        key: null,
        value,
        listGroupKey: selectedGroupKey,
      }),
    );
  }

  /* Dispatches action move items within a group from drag and drop */
  moveItemsWithinGroup(
    list: ListState,
    group: ListGroupState,
    selectedItems: ListItemState[],
    targetIndex: number,
    movingUp: boolean,
  ) {
    const initialTarget = targetIndex;
    const listItemKeys = group.listItemKeys.slice();
    const productNumbers: number[] = [];
    selectedItems.forEach(item => {
      let i = 0;
      productNumbers.push(item?.productNumber);
      for (const itemKey of listItemKeys) {
        if (item?.listItemKey?.listItemId === itemKey?.listItemId) {
          listItemKeys.splice(i, 1);
          if (i <= initialTarget - 1) {
            targetIndex--;
          }
          break;
        }
        i++;
      }
    });
    if (!movingUp) {
      targetIndex++;
    }
    const toItemSequenceNumber = targetIndex;
    selectedItems.forEach(item => {
      listItemKeys.splice(targetIndex++, 0, item?.listItemKey);
    });
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_GROUP_ACTIONS.moveItemsWithinGroupFromDragAndDrop({
          list,
          group,
          toItemSequenceNumber,
          productNumbers,
          listItemKeys,
          isLineItemMove: false,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  productNumbers,
                  groupId: group.listGroupKey.listGroupId,
                  event:
                    ListTracingConstants.moveItemWithinGroupFromDragAndDropEvent,
                  spanName:
                    ListTracingConstants.moveItemWithinGroupFromDragAndDropSpan,
                },
              },
              transformFunc: moveItemsWithinGroupTransformer,
            },
          },
        }),
      );
    });
  }

  /* Determines if we will be moving an item within the same group or to a different group and dispatches appropriate ngrx action */
  moveItemFromSequenceNumber(
    newSeq: number,
    oldSeq: number,
    productNum: number,
    list: ListState,
    listGroups: ListGroupState[],
    listItems: ListItemState[],
  ) {
    if (newSeq === 0) {
      newSeq = 1;
    } else if (newSeq > list?.listItemCount) {
      newSeq = list?.listItemCount + 1;
    }
    if (newSeq === oldSeq) {
      return;
    }
    let sourceGroup: ListGroupState;
    let targetGroup: ListGroupState;
    let counter = 1;
    let targetIndex: number;
    let sourceIndex: number;
    listGroups.forEach(group => {
      const groupItemKeysLength = group?.listItemKeys?.length ?? 0;
      if (oldSeq >= counter && groupItemKeysLength >= oldSeq - counter) {
        sourceGroup = group;
        sourceIndex = oldSeq - counter;
      }
      if (newSeq >= counter && groupItemKeysLength + counter >= newSeq) {
        targetGroup = group;
        targetIndex = newSeq - counter;
      }
      counter += groupItemKeysLength;
    });
    if (
      JSON.stringify(targetGroup) === JSON.stringify(sourceGroup) ||
      !targetGroup
    ) {
      const listItemKeys = sourceGroup?.listItemKeys?.slice();
      const deletedKey = listItemKeys.splice(sourceIndex, 1);
      listItemKeys.splice(targetIndex, 0, deletedKey[0]);
      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.store.dispatch(
          LIST_GROUP_ACTIONS.moveItemsWithinGroupFromLineNumber({
            list,
            group: sourceGroup,
            toItemSequenceNumber: newSeq - 1,
            productNumbers: [productNum],
            listItemKeys,
            isLineItemMove: true,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    productNumbers: [productNum],
                    groupId: sourceGroup.listGroupKey.listGroupId,
                    event:
                      ListTracingConstants.moveItemWithinGroupFromLineNumberEvent,
                    spanName:
                      ListTracingConstants.moveItemWithinGroupFromLineNumberSpan,
                  },
                },
                transformFunc: moveItemsWithinGroupTransformer,
              },
            },
          }),
        );
      });
    } else {
      const sourceGroupItemKeys = sourceGroup?.listItemKeys?.slice();
      const deletedKey = sourceGroupItemKeys.splice(sourceIndex, 1);
      const targetGroupItemKeys = targetGroup?.listItemKeys?.slice();
      const newItems = [];
      const currentItem = { ...listItems[oldSeq - 1] };
      let index = 0;
      let isInTarget = false;
      listItems.forEach(item => {
        if (
          item.productNumber === currentItem.productNumber &&
          JSON.stringify(item.listGroupKey) ===
            JSON.stringify(targetGroup.listGroupKey)
        ) {
          isInTarget = true;
          const deletedTargetKey = targetGroupItemKeys.splice(index, 1);
          targetGroupItemKeys.unshift(deletedTargetKey[0]);
        }
        if (
          JSON.stringify(item.listGroupKey) ===
          JSON.stringify(targetGroup.listGroupKey)
        ) {
          index++;
        }
      });
      if (!isInTarget) {
        targetGroupItemKeys.splice(targetIndex, 0, deletedKey[0]);
        newItems.push({
          ...listItems[oldSeq - 1],
          listGroupKey: targetGroup.listGroupKey,
        });
      }
      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.store.dispatch(
          LIST_GROUP_ACTIONS.moveItemsToAnotherGroupFromLineNumber({
            list,
            sourceGroup,
            targetGroup,
            sourceGroupItemKeys,
            targetGroupItemKeys,
            productNumbers: [productNum],
            listItems: newItems,
            isLineItemMove: true,
            toItemSequenceNumber: newSeq,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    productNumbers: [productNum],
                    sourceGroupId: sourceGroup.listGroupKey.listGroupId,
                    targetGroupId: targetGroup.listGroupKey.listGroupId,
                    event:
                      ListTracingConstants.moveItemToAnotherGroupFromLineNumberEvent,
                    spanName:
                      ListTracingConstants.moveItemToAnotherGroupFromLineNumberSpan,
                  },
                },
                transformFunc: moveItemsToAnotherGroupTransformer,
              },
            },
          }),
        );
      });
    }
  }

  createListItemNote(list: List, item: ListItemState, note: string) {
    this.store.dispatch(
      LIST_ITEM_ACTIONS.createNote({
        list,
        productNumber: item.productNumber,
        note,
      }),
    );
  }

  editListItemNote(list: List, item: ListItemState, note: string) {
    this.store.dispatch(
      LIST_ITEM_ACTIONS.updateNote({
        list,
        productNumber: item.productNumber,
        note,
      }),
    );
  }

  deleteProducts(
    list: List,
    group: SelectedGroup,
    selectedItems: SelectedItem[],
  ) {
    let updatedListGroup: ListGroup;
    let newGroupListItemKeys: ListItemKey[] = [];
    const itemsToRemove: ListItemState[] = [];
    const itemKeysToRemove: string[] = [];
    const productNumbersForTracing: number[] = [];
    if (
      JSON.stringify(group.groupState.listGroupKey) ===
      JSON.stringify(selectedItems[0].item.listGroupKey)
    ) {
      const oldListItemKeys = group.groupState.listItemKeys.slice();
      const itemMap: Map<string, number> = new Map();
      selectedItems.forEach(item => {
        const itemKey = createListItemKey(
          item.item.listKey,
          item.item.listGroupKey,
          item.item.listItemKey,
        );
        itemKeysToRemove.push(itemKey);
        itemsToRemove.push(item.item);
        itemMap.set(itemKey, 1);
        productNumbersForTracing.push(item.product.productNumber);
      });
      for (let i = 0; i < oldListItemKeys.length; i++) {
        const itemKey = createListItemKey(
          list.listKey,
          group.groupState.listGroupKey,
          oldListItemKeys[i],
        );
        if (!itemMap.has(itemKey)) {
          newGroupListItemKeys.push(oldListItemKeys[i]);
        }
      }
    } else {
      const productNumberMap: Map<number, number> = new Map();
      selectedItems.forEach(item => {
        productNumberMap.set(item.product.productNumber, 1);
        productNumbersForTracing.push(item.product.productNumber);
      });
      group.items.forEach(itemInGroup => {
        if (productNumberMap.has(itemInGroup.productNumber)) {
          itemsToRemove.push(itemInGroup);
          itemKeysToRemove.push(
            createListItemKey(
              itemInGroup.listKey,
              itemInGroup.listGroupKey,
              itemInGroup.listItemKey,
            ),
          );
        } else {
          newGroupListItemKeys.push(itemInGroup.listItemKey);
        }
      });
    }
    updatedListGroup = {
      ...group.groupState,
      listItemKeys: newGroupListItemKeys,
    };

    if (itemsToRemove.length < 1) {
      this.loadingSpinnerService.createSpinnerModal().then(() => {
        this.loadingSpinnerService.dismissSpinnerModal();
      });
      return;
    }
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ITEM_ACTIONS.delete({
          list: list,
          originalGroups: [group.groupState],
          updatedGroups: [updatedListGroup],
          listItems: itemsToRemove,
          listItemKeysToRemove: itemKeysToRemove,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  productNumbers: productNumbersForTracing,
                  groupId: group.groupState.listGroupKey.listGroupId,
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  event: ListTracingConstants.deleteItemsEvent,
                  spanName: ListTracingConstants.deleteItemsSpan,
                },
              },
              transformFunc: deleteItemsTransformer,
            },
          },
        }),
      );
    });
  }

  addProductsToList(
    list: List,
    listGroup: ListGroup,
    products: Product[],
    atGroupLocation: string,
    addMethod: string,
    merchFeatureName: string,
    showAlternativeToast: boolean = false,
    addMultipleEntryPoint: boolean = false,
  ) {
    const productNumbers: number[] = products.map(
      product => product.productNumber,
    );
    this.filterProductNumbers$(
      list.listKey,
      listGroup.listGroupKey,
      productNumbers,
    )
      .pipe(take(1))
      .subscribe(filteredProdNumbers => {
        if (filteredProdNumbers.length == 0) {
          this.toastService.presentToastMsg(
            this.translateService.instant('i18n.lists.productAlreadyExists'),
            'red-toast',
            MessageTypeEnum.error,
            [],
          );
          return;
        }
        const items = [];
        let tempIds = [];
        filteredProdNumbers?.forEach(prodNumber => {
          const tempId = Math.floor(-100000000 * Math.random());
          const listItemKey = {
            listTypeId: list.listKey.listTypeId,
            listItemId: tempId,
          } as ListItemKey;
          const item = {
            productNumber: prodNumber,
            listItemKey: listItemKey,
            r4TempListItemId: tempId,
            listKey: list.listKey,
            listGroupKey: listGroup.listGroupKey,
          } as ListItem;
          tempIds.push(tempId);
          items.push(item);
        });
        let productData = products.map(product => ({
          divisionApn:
            product.summary.divisionNumber + '-' + product.productNumber,
          attributes: product.trackingAttributes,
          ...(!!product.resultRank && { resultRank: product.resultRank }),
          ...(!!product.merchFeatureName && {
            merchFeatureName: product.merchFeatureName,
            addMethod,
          }),
        }));
        this.listAnalyticsHelperService.trackAddToList({
          products: productData,
          listID: list.listKey.listId,
          listType: list.listKey.listTypeId,
          addMethod,
          merchFeatureName,
        });
        this.loadingSpinnerService.createSpinnerModal().then(() => {
          this.store.dispatch(
            LIST_ITEM_ACTIONS.create({
              list: list,
              updatedGroup: listGroup,
              listItems: items,
              atGroupLocation: atGroupLocation,
              version: list.version.toString(),
              tracking: {
                tracing: {
                  data: {
                    traceContext: ListTracingConstants.listMaintenance,
                    isEndOfTrace: false,
                    isStartOfTrace: true,
                    attributes: {
                      products: productData,
                      r4TempItemIds: tempIds,
                      listType: list.listKey.listTypeId,
                      listId: list.listKey.listId,
                      event: ListTracingConstants.addToListEvent,
                      spanName: ListTracingConstants.addToListSpan,
                    },
                  },
                  transformFunc: addProductsToListsTransformer,
                },
              },
              showAlternativeToast,
              addMultipleEntryPoint,
            }),
          );
          this.setGroupPositionSelection(atGroupLocation);
        });
      });
  }

  setGroupPositionSelection = async positionInGroup => {
    const oldPreferences: Preferences = await firstValueFrom(
      this.userService.userPreferences$(),
    );
    if (
      !!oldPreferences &&
      oldPreferences.preferences.positionInGroup === positionInGroup
    ) {
      return;
    }
    const newPreferences = {
      ...oldPreferences,
      preferences: {
        ...oldPreferences?.preferences,
        recentlyViewed: oldPreferences?.preferences?.recentlyViewed ?? [],
        positionInGroup,
      },
    } as Preferences;
    this.store.dispatch(
      UserActions.updateUserPreferences({
        newPreferences,
        oldPreferences,
      }),
    );
  };

  copyProducts(
    list: ListState,
    sourceGroupKey: ListGroupKey,
    selectedGroups: SelectedGroup[],
    selectedProducts: SelectedItem[],
  ) {
    const groupIds: string[] = [];
    const tempItems: TempItemKey[] = [];
    const groupsWithUpdatedItemKeys: ListGroupState[] = [];
    let listItemsToInsert: ListItemState[] = [];
    let productNumbersForTracing: number[] = [];
    selectedProducts.forEach(selectedProduct => {
      const tempId = Math.floor(-100000000 * Math.random());
      const tempItem = {
        productNumber: selectedProduct.product.productNumber,
        r4TempListItemId: tempId,
      } as TempItemKey;
      tempItems.push(tempItem);
      productNumbersForTracing.push(selectedProduct.product.productNumber);
    });
    selectedGroups.forEach(group => {
      if (group.isSelected) {
        groupIds.push(group.groupState.listGroupKey.listGroupId);
        const ret = this.generateNewTempItemsForCopy(tempItems, list, group);
        listItemsToInsert = listItemsToInsert.concat(ret.newTempItems);
        groupsWithUpdatedItemKeys.push({
          ...group.groupState,
          listItemKeys: ret.finalKeys,
        });
      }
    });
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        LIST_ITEM_ACTIONS.copy({
          list,
          sourceGroupKey,
          tempItemKeys: tempItems,
          tempItemsToInsert: listItemsToInsert,
          tempGroupsToInsert: groupsWithUpdatedItemKeys,
          groupIds,
          tracking: {
            tracing: {
              data: {
                traceContext: ListTracingConstants.listMaintenance,
                isEndOfTrace: false,
                isStartOfTrace: true,
                attributes: {
                  productNumbers: productNumbersForTracing,
                  sourceGroupId: sourceGroupKey.listGroupId,
                  targetGroupIds: groupIds,
                  listId: list.listKey.listId,
                  listType: list.listKey.listTypeId,
                  event: ListTracingConstants.copyItemsEvent,
                  spanName: ListTracingConstants.copyItemsSpan,
                },
              },
              transformFunc: copyItemsTransformer,
            },
          },
        }),
      );
    });
  }

  generateNewTempItemsForCopy(
    tempItemKeys: TempItemKey[],
    list: ListState,
    targetGroup: SelectedGroup,
  ) {
    let targetGroupKeys = targetGroup?.groupState?.listItemKeys?.slice() || [];
    let targetGroupItems = targetGroup?.items?.slice() || [];
    const targetKeysToConcat = [];
    const newTempItems = [];
    tempItemKeys.forEach(tempKey => {
      let existsInGroup = false;
      let index = 0;
      targetGroupItems?.forEach(item => {
        if (item.productNumber === tempKey.productNumber) {
          existsInGroup = true;
          const removedKey = targetGroupKeys.splice(index, 1);
          targetGroupItems.splice(index, 1);
          targetKeysToConcat.push(removedKey[0]);
        }
        index++;
      });
      if (!existsInGroup) {
        const listItemKey = {
          listTypeId: list.listKey.listTypeId,
          listItemId: tempKey.r4TempListItemId,
        } as ListItemKey;
        const item = {
          productNumber: tempKey.productNumber,
          listItemKey: listItemKey,
          r4TempListItemId: tempKey.r4TempListItemId,
          listKey: list.listKey,
          listGroupKey: targetGroup.groupState.listGroupKey,
        } as ListItem;
        targetKeysToConcat.push(listItemKey);
        newTempItems.push(item);
      }
    });
    const finalKeys = targetKeysToConcat.concat(targetGroupKeys);
    return { newTempItems, finalKeys };
  }

  /* Dispatches action move items to another group. Either from modal or drag and drop  */
  moveItemsToAnotherGroup(
    list: ListState,
    targetGroup: ListGroupState,
    sourceGroup: ListGroupState,
    selectedItems: ListItemState[],
    allListItemsOnList: ListItemState[],
    isDragAndDrop: boolean,
  ) {
    const sourceGroupItemKeys = sourceGroup?.listItemKeys?.slice();
    const targetGroupItemKeys = targetGroup?.listItemKeys?.slice() || [];
    const iterableListItems = allListItemsOnList?.slice() || [];
    const tempArrToPush = [];
    const newItems = [];
    const productNumbers: number[] = [];
    selectedItems.forEach(selectedItem => {
      let index = 0;
      let targetGroupIndex = 0;
      let sourceGroupIndex = 0;
      let targetItemIndex = 0;
      let sourceItemIndex = 0;
      let isInTarget = false;
      let currentGroup = null;
      let currentGroupIndex = 0;
      productNumbers.push(selectedItem?.productNumber);
      for (const item of iterableListItems) {
        const noCurrGroupOrCurrItemIsNotInCurrGroup =
          !currentGroup ||
          JSON.stringify(item?.listGroupKey) !== JSON.stringify(currentGroup);
        if (noCurrGroupOrCurrItemIsNotInCurrGroup) {
          currentGroup = item?.listGroupKey;
          currentGroupIndex = 0;
        }

        const foundProductInTargetGroup =
          item?.productNumber === selectedItem?.productNumber &&
          JSON.stringify(item?.listGroupKey) ===
            JSON.stringify(targetGroup?.listGroupKey);
        const foundItemInSourceGroup =
          item?.productNumber === selectedItem?.productNumber &&
          JSON.stringify(item?.listGroupKey) ===
            JSON.stringify(sourceGroup?.listGroupKey);

        if (foundProductInTargetGroup) {
          isInTarget = true;
          targetGroupIndex = currentGroupIndex;
          targetItemIndex = index;
        } else if (foundItemInSourceGroup) {
          sourceGroupIndex = currentGroupIndex;
          sourceItemIndex = index;
        }
        index++;
        currentGroupIndex++;
      }
      if (isInTarget) {
        sourceGroupItemKeys.splice(sourceGroupIndex, 1);
        iterableListItems.splice(sourceItemIndex, 1);
        let targetRow = targetItemIndex;
        if (sourceItemIndex < targetItemIndex) {
          targetRow--;
        }
        iterableListItems.splice(targetRow, 1);
        const keyToAdd = targetGroupItemKeys.splice(targetGroupIndex, 1);
        tempArrToPush.push(keyToAdd[0]);
      } else {
        const keyToAdd = sourceGroupItemKeys.splice(sourceGroupIndex, 1);
        iterableListItems.splice(sourceItemIndex, 1);
        tempArrToPush.push(keyToAdd[0]);
        newItems.push({
          ...selectedItem,
          listGroupKey: targetGroup.listGroupKey,
        });
      }
    });
    const finalTargetKeys = tempArrToPush.concat(targetGroupItemKeys);

    this.loadingSpinnerService.createSpinnerModal().then(() => {
      if (isDragAndDrop) {
        this.store.dispatch(
          LIST_GROUP_ACTIONS.moveItemsToAnotherGroupFromDragAndDrop({
            list,
            sourceGroup,
            targetGroup,
            sourceGroupItemKeys,
            targetGroupItemKeys: finalTargetKeys,
            productNumbers,
            listItems: newItems,
            isLineItemMove: false,
            toItemSequenceNumber: null,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    productNumbers,
                    sourceGroupId: sourceGroup.listGroupKey.listGroupId,
                    targetGroupId: targetGroup.listGroupKey.listGroupId,
                    event:
                      ListTracingConstants.moveItemToAnotherGroupFromDragAndDropEvent,
                    spanName:
                      ListTracingConstants.moveItemToAnotherGroupFromDragAndDropSpan,
                  },
                },
                transformFunc: moveItemsToAnotherGroupTransformer,
              },
            },
          }),
        );
      } else {
        this.store.dispatch(
          LIST_GROUP_ACTIONS.moveItemsToAnotherGroupFromModal({
            list,
            sourceGroup,
            targetGroup,
            sourceGroupItemKeys,
            targetGroupItemKeys: finalTargetKeys,
            productNumbers,
            listItems: newItems,
            isLineItemMove: false,
            toItemSequenceNumber: null,
            tracking: {
              tracing: {
                data: {
                  traceContext: ListTracingConstants.listMaintenance,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    listId: list.listKey.listId,
                    listType: list.listKey.listTypeId,
                    productNumbers,
                    sourceGroupId: sourceGroup.listGroupKey.listGroupId,
                    targetGroupId: targetGroup.listGroupKey.listGroupId,
                    event:
                      ListTracingConstants.moveItemToAnotherGroupFromModalEvent,
                    spanName:
                      ListTracingConstants.moveItemToAnotherGroupFromModalSpan,
                  },
                },
                transformFunc: moveItemsToAnotherGroupTransformer,
              },
            },
          }),
        );
      }
    });
  }

  private assignNewProdNumForReplaceItems(
    listItems: ListItem[],
    replacementProductNumber: number,
  ) {
    const newListItems = [];
    listItems.forEach(item => {
      const newListItem = { ...item, productNumber: replacementProductNumber };
      newListItems.push(newListItem);
    });
    return newListItems;
  }

  updateRecentlyViewedUserPreferences(newList: string) {
    this.userService
      .userPreferencesState$()
      .pipe(
        filter(
          (userPreferencesState: UserPreferencesState) =>
            userPreferencesState?.loaded,
        ),
        take(1),
      )
      .subscribe((userPreferencesState: UserPreferencesState) => {
        let newPreferences: Preferences = {
          preferences: {
            recentlyViewed: [],
            positionInGroup: AddToGroupPosition.Top,
          },
        };
        const preferences = userPreferencesState?.preferences;
        let newRecentlyViewed = [];
        if (preferences?.preferences?.recentlyViewed) {
          newRecentlyViewed = preferences.preferences.recentlyViewed.slice();
          if (preferences.preferences.recentlyViewed.length === 0) {
            newRecentlyViewed.push(newList);
          } else if (preferences.preferences.recentlyViewed.length === 1) {
            if (!newRecentlyViewed.includes(newList)) {
              newRecentlyViewed.unshift(newList);
            }
          } else if (preferences.preferences.recentlyViewed.length === 2) {
            if (newRecentlyViewed[0] !== newList) {
              newRecentlyViewed.pop();
              newRecentlyViewed.unshift(newList);
            }
          }
        } else {
          newRecentlyViewed.push(newList);
        }
        newPreferences = {
          ...preferences,
          preferences: {
            ...preferences?.preferences,
            recentlyViewed: newRecentlyViewed,
            positionInGroup: preferences?.preferences?.positionInGroup
              ? preferences?.preferences?.positionInGroup
              : AddToGroupPosition.Top,
          },
        };
        this.store.dispatch(
          UserActions.updateUserPreferences({
            newPreferences,
            oldPreferences: preferences,
          }),
        );
      });
  }

  updateSortAndFilterUserPreferences(
    listKey: string,
    sortType: string,
    groupToSort: string,
    filters: string[],
  ) {
    this.userService
      .userPreferencesState$()
      .pipe(
        filter(
          (userPreferencesState: UserPreferencesState) =>
            userPreferencesState?.loaded,
        ),
        take(1),
      )
      .subscribe((userPreferencesState: UserPreferencesState) => {
        let newSortAndFilterApplied: SortFilter = {
          ...userPreferencesState?.preferences?.preferences
            ?.sortAndFilterApplied,
        };

        const userPreferences = userPreferencesState?.preferences;
        const isDefaultSortAndFilters =
          (ListConstants.recentlyPurchased === listKey
            ? sortType === ListConstants.date
            : sortType === ListConstants.groupLine) &&
          groupToSort === ListConstants.allGroups &&
          filters.length === 0;

        const currentSavedSortFilter = newSortAndFilterApplied[listKey];

        const transformedFilters = filters.join();
        const newSortAndFiltersAreTheSame =
          currentSavedSortFilter?.sortOption === sortType &&
          currentSavedSortFilter?.sortBy === groupToSort &&
          currentSavedSortFilter?.filterOption === transformedFilters;

        if (isDefaultSortAndFilters && !!currentSavedSortFilter) {
          delete newSortAndFilterApplied[listKey];
        } else if (
          isDefaultSortAndFilters ||
          (!!currentSavedSortFilter && newSortAndFiltersAreTheSame)
        ) {
          return;
        } else {
          newSortAndFilterApplied[listKey] = {
            sortOption: sortType,
            sortBy: groupToSort,
            filterOption: transformedFilters,
          };
        }

        let newPreferences: Preferences = {
          ...userPreferences,
          preferences: {
            ...userPreferences?.preferences,
            // Have to do this since it is required in the object. TODO: Make this field an optional in the types
            recentlyViewed:
              userPreferencesState?.preferences?.preferences?.recentlyViewed ??
              [],
            sortAndFilterApplied: newSortAndFilterApplied,
          },
        };
        this.store.dispatch(
          UserActions.updateUserPreferences({
            newPreferences,
            oldPreferences: userPreferencesState?.preferences,
          }),
        );
      });
  }

  goToSearchPage() {
    this.router.navigate(['search'], {
      queryParams: {
        searchFilterProperties: '0',
        mode: UsfProductCardModeEnum.addToListSearch,
      },
    });
  }

  removeImportError(listKey: ListKey) {
    this.store.dispatch(
      LIST_ACTIONS.removeImportError({
        listKey,
      }),
    );
  }

  closeCopyModal() {
    this.CopyModalRef?.dismissModal();
  }

  sortByName = (nameA: string, nameB: string) => {
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    } else if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    return 0;
  };

  sortByCustomer = (customerA: Customer, customerB: Customer) => {
    if (
      customerA.customerName.toLowerCase() >
      customerB.customerName.toLowerCase()
    ) {
      return 1;
    } else if (
      customerA.customerName.toLowerCase() <
      customerB.customerName.toLowerCase()
    ) {
      return -1;
    }
    return 0;
  };

  sortByDepartment = (
    customerA: Customer,
    customerB: Customer,
    deptNumA: number,
    deptNumB: number,
  ) => {
    if (customerA.departments.length === 0) {
      return 0;
    }
    const deptA = customerA.departments.find(
      dept => dept.departmentNumber === deptNumA.toString(),
    );
    const deptB = customerB.departments.find(
      dept => dept.departmentNumber === deptNumB.toString(),
    );
    if (
      deptA.departmentName.toLowerCase() > deptB.departmentName.toLowerCase()
    ) {
      return 1;
    } else if (
      deptA.departmentName.toLowerCase() < deptB.departmentName.toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  getCustomerMapDivisionMapAndAccessibleListCustomerData = async () => {
    const customers = await firstValueFrom(this.store.select(getCustomers));
    const customerMap = new Map<number, Customer>();

    const custDivDeptInfo = [];
    customers.forEach(customer => {
      customerMap.set(customer.customerNumber, customer);
      customer.departments.forEach(dept => {
        custDivDeptInfo.push({
          customerNumber: customer.customerNumber,
          divisionNumber: Number(customer.divisionNumber).valueOf(),
          departmentNumber: Number(dept.departmentNumber).valueOf(),
        });
      });
      if (customer.departments.length === 0) {
        custDivDeptInfo.push({
          customerNumber: customer.customerNumber,
          divisionNumber: Number(customer.divisionNumber).valueOf(),
          departmentNumber: 0,
        });
      }
    });
    const divisionMap = await firstValueFrom(
      this.store.select(getDivisionEntities),
    );

    return {
      customerMap,
      divisionMap,
      custDivDeptInfo,
    };
  };

  requestLoadAndGiveAccessibleListData$ = async () => {
    const { customerMap, divisionMap, custDivDeptInfo } =
      await this.getCustomerMapDivisionMapAndAccessibleListCustomerData();

    this.store.dispatch(ACCESSIBLE_LIST_ACTIONS.load({ custDivDeptInfo }));

    return this.store.select(selectAllAccessibleListState).pipe(
      map(listData => {
        const dataToReturn = [];
        let trackingCustomerDivision = '';
        let trackingLastHeaderIndex = 0;

        listData
          .sort((a, b) => {
            const customerResult = this.sortByCustomer(
              customerMap.get(a.customerNumber),
              customerMap.get(b.customerNumber),
            );
            if (customerResult !== 0) return customerResult;

            const departmentResult = this.sortByDepartment(
              customerMap.get(a.customerNumber),
              customerMap.get(b.customerNumber),
              a.departmentNumber,
              b.departmentNumber,
            );
            if (departmentResult !== 0) return departmentResult;

            return this.sortByName(a.listName, b.listName);
          })
          .forEach(list => {
            const customer = customerMap.get(list.customerNumber);
            let deptData = customer.departments.find(
              dept =>
                dept.departmentNumber === list.departmentNumber.toString(),
            );

            let divisionData = divisionMap[customer.divisionNumber];

            if (!deptData) {
              deptData = {
                divisionNumber: divisionData?.divisionNumber ?? 0,
                customerNumber: customer.customerNumber,
                departmentNumber: '0',
                departmentName: '',
                deliveryInstructions: '',
                departmentActive: false,
              };
            }

            if (!divisionData) {
              divisionData = {
                divisionNumber: 0,
                divisionName: '',
              } as unknown as Division;
            }

            let searchData = [
              list.listName.toLowerCase(),
              customer.customerName.toLowerCase(),
              list.customerNumber.toString(),
              list.divisionNumber.toString(),
              list.departmentNumber.toString(),
            ];

            if (!!deptData.departmentName) {
              searchData.push(deptData.departmentName.toLowerCase());
            }

            if (!!divisionData.divisionName) {
              searchData.push(divisionData?.divisionName.toLowerCase());
            }

            if (
              trackingCustomerDivision !==
              list.customerNumber + '-' + list.departmentNumber
            ) {
              trackingCustomerDivision =
                list.customerNumber + '-' + list.departmentNumber;
              const custName =
                customer.customerName + ' (' + customer.customerNumber + ')';

              const departmentName = !!deptData.departmentName
                ? deptData.departmentName +
                  ' (' +
                  deptData.departmentNumber +
                  ')'
                : '';

              const divisionName = !!divisionData?.divisionName
                ? divisionData.divisionName +
                  ' (' +
                  divisionData.divisionNumber +
                  ')'
                : '';

              let label = custName;

              if (!!departmentName) {
                label += ' - ' + departmentName;
              }

              if (!!divisionName) {
                label += ' - ' + divisionName;
              }

              dataToReturn.push({
                label,
                selected: false,
                value: {
                  custNum: customer.customerNumber,
                  deptNum: deptData.departmentNumber,
                  divNum: divisionData.divisionNumber,
                },
                type: MultiSelectItems.selectableHeader,
                group: list.customerNumber + '-' + list.departmentNumber,
                searchArray: [...searchData],
              });
              trackingLastHeaderIndex = dataToReturn.length - 1;
            } else {
              dataToReturn[trackingLastHeaderIndex].searchArray.push(
                list.listName.toLowerCase(),
              );
            }
            const label = list.listName;
            label.trim();
            dataToReturn.push({
              label,
              value: list.listKey,
              selected: false,
              type: MultiSelectItems.subItem,
              group: list.customerNumber + '-' + list.departmentNumber,
              searchArray: searchData,
            });
          });

        return dataToReturn;
      }),
    );
  };

  getIsAccessibleLoaded$ = () => {
    return this.store.select(selectIsAccessibleListStateLoaded);
  };

  submitCopyToListData = (
    targetListKeys: ListKey[],
    productNumbers: number[],
  ) => {
    this.store.dispatch(
      ACCESSIBLE_LIST_ACTIONS.copyToMultiple({
        targetListKeys,
        atGroupLocation: 'TOP',
        productNumbers,
      }),
    );
  };

  showCopyToList = async () => {
    const lists = await firstValueFrom(this.store.select(selectListsState));
    const customers = await firstValueFrom(this.store.select(getCustomers));
    return lists.ids.length > 1 || customers.length > 1;
  };
}
