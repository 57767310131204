import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductStateService } from '@usf/ngrx-product';
import { Subscription } from 'rxjs';
import {
  Typeahead,
  TypeaheadItem,
  TypeaheadGroupNames,
  TypeaheadGroup,
} from '@usf/product-types';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { SearchAnalyticsService } from 'src/app/search/services/search-analytics.service';
import { SEARCH_TYPE } from '@usf/ngrx-product';
import { SEARCH_PAGES_ENUM } from 'src/app/shared/constants/search-pages.enum';
import { ToastController } from '@ionic/angular';
import { TYPEAHEAD_DEBOUNCE_MS } from '@shared/constants/typeahead-constants';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { COVEO_STANDALONE_SEARCH_BOX_DATA } from '@shared/constants/coveo-constants';
import { StandaloneSearchBoxAnalytics } from '@coveo/headless';

@Component({
  selector: 'app-usf-search-typeahead',
  templateUrl: './usf-search-typeahead.component.html',
  styleUrls: ['./usf-search-typeahead.component.scss'],
})
export class UsfSearchTypeaheadComponent implements OnInit, OnDestroy {
  typeaheadVisible = false;
  typeaheadNoResults = false;
  typeahead: Typeahead;
  typeahead$: Subscription;
  typeaheadDebounce: number = TYPEAHEAD_DEBOUNCE_MS;
  minLength = 2;
  activeIndex = -1;
  customTypeAheadGroup: TypeaheadGroup[] = [];

  @ViewChild('searchCatalog') searchInput: HTMLIonSearchbarElement;

  routeEvents$: Subscription;

  constructor(
    private productStateService: ProductStateService,
    private searchAnalyticsService: SearchAnalyticsService,
    private navigationHelper: NavigationHelperService,
    public router: Router,
    private toastController: ToastController,
    private readonly toggleNewSearchService: ToggleNewSearchService,
    private navigationHelperService: NavigationHelperService,
  ) {}

  ngOnInit(): void {
    this.routeEvents$ = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(ev => {
        this.clearInputText();
        this.cancelTypeahead();
      });
  }

  ngOnDestroy(): void {
    this.routeEvents$?.unsubscribe();
    this.typeahead$?.unsubscribe();
  }

  handleInputFocus(evt) {
    this.toastController.getTop().then(data => {
      if (data) {
        this.toastController.dismiss();
      }
    });
    const inputValue = evt?.target?.value?.trim();
    if (inputValue.length > this.minLength && !this.typeaheadNoResults) {
      this.typeaheadVisible = true;
    }
  }

  handleInputChange(evt) {
    const inputValue = evt?.target?.value?.trim();
    if (inputValue.length > this.minLength) {
      this.typeahead$?.unsubscribe();
      this.typeahead$ = this.productStateService
        .getTypeahead(inputValue)
        .subscribe(result => {
          this.typeahead = this.filterGroupsWithoutItems(result);
          this.typeaheadVisible = true;
          this.typeaheadNoResults = !result?.groups?.some(
            g => g.items?.length > 0,
          );
          this.activeIndex = -1;
          this.highlightActiveIndex();
        });
    } else {
      this.typeaheadVisible = false;
    }
  }

  handleInputBlur(evt) {
    // Prevent the component from disappearing before an item can be clicked
    setTimeout(() => {
      this.typeaheadVisible = false;
    }, 300);
  }

  handleSearch(searchCatalog: any) {
    this.searchText(searchCatalog?.value?.trim());
  }

  handleItemClick(item: TypeaheadItem, category: string, rank: number) {
    if (!item || !item.searchDimensionId) {
      return;
    }
    if (category === TypeaheadGroupNames.products) {
      this.searchByProductNumber(item.searchDimensionId, rank);
    } else {
      // For Categories & Brands
      this.searchByDimensionId(item.searchDimensionId);
    }
    this.trackTypeAheadSearch(
      item.description,
      category,
      rank,
      item.searchDimensionId,
    );
  }

  private getItemsCount() {
    return this.typeahead.groups
      .map(group => group.items.length)
      .reduce((itemsCount, length) => itemsCount + length, 0);
  }

  selectNextItem() {
    const itemsCount = this.getItemsCount();
    if (itemsCount > 0) {
      this.activeIndex++;
      this.activeIndex = this.activeIndex % itemsCount;
    }
    return 0;
  }

  selectPreviousItem() {
    const itemsCount = this.getItemsCount();
    if (itemsCount > 0) {
      this.activeIndex--;
      if (this.activeIndex < 0) {
        this.activeIndex = itemsCount - 1;
      }
    }
    return 0;
  }

  handleArrowKey(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.selectNextItem();
        this.highlightActiveIndex();
        break;

      case 'ArrowUp':
        event.preventDefault();
        this.selectPreviousItem();
        this.highlightActiveIndex();
        break;

      case 'Enter':
        this.handleItemClick(
          this.typeahead?.groups?.[0]?.items[this.activeIndex],
          this.typeahead?.groups?.[0]?.name,
          this.activeIndex,
        );
        break;
    }
  }

  highlightActiveIndex() {
    let index = 0;
    this.customTypeAheadGroup = this.typeahead.groups.map(group => {
      const typeAheadGroup = { ...group } as TypeaheadGroup;
      typeAheadGroup.items = typeAheadGroup.items.map(item => {
        const typeAheadItem = { ...item, isActive: false };
        if (index === this.activeIndex) {
          typeAheadItem.isActive = true;
        }
        index++;
        return typeAheadItem;
      });
      return typeAheadGroup;
    });
    setTimeout(() => {
      const activeElement = Array.from(
        document.querySelectorAll('.typeahead li.active'),
      )
        .slice(-1)
        .pop();

      if (this.activeIndex === 0) {
        let typeAheadContainer =
          activeElement?.parentElement?.parentElement?.parentElement;
        if (!!typeAheadContainer) {
          typeAheadContainer.scrollTo({ behavior: 'smooth', top: 0 });
        }
      } else {
        if (!!activeElement) {
          activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }
    }, 100);
  }

  trackTypeAheadSearch(
    term: string,
    category: string,
    rank: number,
    dimensionId?: number,
  ) {
    const searchTerm = this.getTypeAheadTermFormat(
      term.toLowerCase(),
      category,
    );
    this.searchAnalyticsService.trackTypeAheadOptions({
      category,
      term: searchTerm,
      rank,
      searchDimensionId: dimensionId,
    });
  }

  filterGroupsWithoutItems(typeahead: Typeahead): Typeahead {
    if (!typeahead) return typeahead;
    return {
      ...typeahead,
      groups: typeahead.groups?.filter(group => group.items?.length > 0),
    };
  }

  searchText(searchText: string) {
    if (searchText?.trim().length > 0) {
      this.searchAnalyticsService.trackSearchSubmit(
        { searchText },
        SEARCH_TYPE.catalogSearch,
      );
      this.toggleNewSearchService
        .isNewSearchEnabled()
        .pipe(take(1))
        .subscribe(newSearchEnabled => {
          if (newSearchEnabled) {
            const data = {
              value: searchText,
              analytics: {} as StandaloneSearchBoxAnalytics,
            };
            localStorage.setItem(
              COVEO_STANDALONE_SEARCH_BOX_DATA,
              JSON.stringify(data),
            );
            this.navigationHelperService.routeToNewSearch({ searchText });
          } else {
            this.navigationHelperService.routeToSearch({ searchText });
          }
        });

      this.typeaheadVisible = false;
    }
  }

  searchByDimensionId(dimensionId: number) {
    this.navigationHelper.routeToSearch({
      searchFilterProperties: dimensionId,
      originSearchPage: SEARCH_PAGES_ENUM.typeAhead,
    });
    this.typeaheadVisible = false;
  }

  searchByProductNumber(productNumber: number, rank: number) {
    const queryParams = {
      lnksrc: SEARCH_PAGES_ENUM.productTypeAhead,
      rank,
    };
    this.navigationHelper.routeToProductDetailsQueryParams(
      productNumber,
      queryParams,
    );
    this.typeaheadVisible = false;
  }

  clearInputText() {
    if (!!this.searchInput) {
      this.searchInput.value = '';
    }
  }

  cancelTypeahead() {
    this.typeahead$?.unsubscribe();
    this.typeaheadVisible = false;
  }

  getTypeAheadTermFormat(name: string, category: string): string {
    switch (category) {
      case TypeaheadGroupNames.products:
        return `product:${name}`;
      case TypeaheadGroupNames.categories:
        return `category:${name}`;
      case TypeaheadGroupNames.brands:
        return `brand:${name}`;
    }
  }
}
